import axios from "axios";

const axiosClient = axios.create();

axiosClient.defaults.baseURL = `${process.env.REACT_APP_URL_API}/data/`;

axiosClient.defaults.headers.common.Authorization =
  new URLSearchParams(window.location.search).get("token") || "";

export default axiosClient;
