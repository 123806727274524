import { chatDTO } from "../common/dtos/chatDTO";

export const sortByDate = (a: chatDTO, b: chatDTO): number => {
  if (a.time_msg && b.time_msg) {
    if (new Date(a.time_msg).getTime() > new Date(b.time_msg).getTime())
      return -1;
    if (new Date(a.time_msg).getTime() < new Date(b.time_msg).getTime())
      return 1;
    return 0;
  }
  if (a.time_msg) return -1;
  if (b.time_msg) return 1;

  return a.nome
    .normalize("NFD")
    .replace(/[^a-zA-Zs]/g, "")
    .toLowerCase() <
    b.nome
      .normalize("NFD")
      .replace(/[^a-zA-Zs]/g, "")
      .toLowerCase()
    ? -1
    : 1;
};
