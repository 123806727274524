export const sendMessageToParentWindow = (event: string, data?: any) => {
  const message = {
    event,
    value: data,
    fromChat: true,
  };

  // eslint-disable-next-line no-unused-expressions
  window.parent?.postMessage(message, "*");
};
