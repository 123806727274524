import { FC, memo, useContext, useEffect, useState } from "react";
import { Socket } from "socket.io-client";

import "../styles/chatlist.scss";

import {
  FaAngleDoubleRight,
  FaAngleRight,
  FaAngleDoubleLeft,
  FaAngleLeft,
} from "react-icons/fa";
import { createRipples } from "react-ripples";
import { Spinner } from "react-bootstrap";
import { formatMessageDate } from "./helpers/formatMessageDate";
import { chatDTO } from "../common/dtos/chatDTO";
import getChats from "../api/getChats";
import { SocketContext } from "../context/SocketContext";
import { setStatusClass } from "../utils/setStatusClass";
import { ErrorToast } from "../common/toast/toast";
import { useChat } from "../hooks/useChat";
import { useChats } from "../hooks/useChats";
import { useUser } from "../hooks/useUser";
import SupportCards from "./support-cards";
import { transparentize, cssVar } from "polished";

const ItemRipples = createRipples({
  color: transparentize(0.8, cssVar("--colors-primary", "purple") as string),
  during: 650,
});

const ChatList: FC = () => {
  const socket: Socket = useContext(SocketContext);

  const { onOpenChat } = useChat();
  const { chats, setChatList } = useChats();
  const { user } = useUser();

  const [loading, setLoading] = useState<boolean>(false);

  // busca pelas conversas
  useEffect(() => {
    setLoading(true);
    getChats(user.codu)
      .then((res: chatDTO[]) => {
        setChatList(res);
        setLoading(false);

        socket.emit("busca_status_usuarios", res);
      })
      .catch(() => {
        setLoading(false);
        ErrorToast.fire({
          text: "Erro ao buscar conversas",
        });
      });
  }, [user.codu, socket]);

  // Retorno da busca de todos
  useEffect(() => {
    socket.on("resposta_busca_status_usuarios", (newChats: chatDTO[]) => {
      setChatList(newChats);
    });

    return () => {
      socket.off("resposta_busca_status_usuarios");
    };
  }, []);

  // Adiciona um icone informando se a mensagem foi lida, ou apenas enviada
  const setMessageIcon = (chatInfo: chatDTO): JSX.Element => {
    if (Number(chatInfo.sender) === Number(user.codu)) {
      if (chatInfo.seen === 1) {
        return <FaAngleDoubleLeft size={11} />;
      }
      return <FaAngleLeft size={11} />;
    }
    if (chatInfo.seen === 1) {
      return <FaAngleDoubleRight size={11} />;
    }
    if (chatInfo.seen === 0) {
      return <FaAngleRight size={11} />;
    }

    return <></>;
  };

  // Abre o chat e seta os cookies necessarios
  const handleOpenChat = (openedChat: chatDTO): void => {
    // Timeout para animação
    setTimeout(() => {
      localStorage.setItem("chat_window", "talk");
      localStorage.setItem("chat_open", "true");
      localStorage.setItem("talk_info", JSON.stringify(openedChat));
      onOpenChat({ ...openedChat, notif: false });
    }, 150);
  };

  return (
    <div className="component">
      <SupportCards />
      {!loading ? (
        <div
          className="list"
          style={
            {
              "--pulse-color": transparentize(
                0.8,
                cssVar("--colors-primary", "purple") as string
              ),
            } as React.CSSProperties
          }
        >
          {chats.length > 0 &&
            chats.map((chatItem: chatDTO, index: number) => {
              if (Number(chatItem.codu) === Number(user.codu)) return false;
              return (
                <ItemRipples
                  className={`chat waves-effect ${
                    chatItem.hidden ? "hide" : ""
                  } ${chatItem.notif ? "notif" : ""}`}
                  key={index}
                  onClick={() => handleOpenChat(chatItem)}
                >
                  <div className="chat-img">
                    <img
                      className={setStatusClass(Number(chatItem.chat_status))}
                      src={chatItem.img}
                      title={chatItem.nome}
                    />
                  </div>
                  <div className="chat-info">
                    <span className="user-name" title={chatItem.nome}>
                      {chatItem.nome}
                    </span>
                    <div className="message-row">
                      {chatItem.message ? (
                        <>
                          <div className="last-message">
                            {setMessageIcon(chatItem)}
                            <span className="message">
                              {chatItem.message ?? ""}
                            </span>
                          </div>
                          <span className="message-date">
                            {chatItem.time_msg
                              ? formatMessageDate(chatItem.time_msg)
                              : ""}
                          </span>
                        </>
                      ) : (
                        "Nenhuma mensagem"
                      )}
                    </div>
                  </div>
                  {chatItem.unread && chatItem.unread > 0 ? (
                    <span className="unread-badge" title="Mensagens não lidas">
                      {+chatItem.unread}
                    </span>
                  ) : null}
                </ItemRipples>
              );
            })}
        </div>
      ) : (
        <div
          className="loading"
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Carregando...</span>
          </Spinner>
        </div>
      )}
    </div>
  );
};

export default memo(ChatList);
