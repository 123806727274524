import { useContext, useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Chat } from "../components/chat";
import ChatList from "../components/chatlist";
import { Header } from "../components/header";
import { SocketContext } from "../context/SocketContext";
import getOfflineMessages from "../api/getOfflineMessages";
import getMessages from "../api/getMessages";
import { notificar } from "../utils/notificar";
import updateSeen from "../api/updateSeen";
import getChats from "../api/getChats";
import { chatDTO } from "../common/dtos/chatDTO";
import { CustomToast, ErrorToast } from "../common/toast/toast";
import { useChat } from "../hooks/useChat";
import { useUser } from "../hooks/useUser";
import { useChats } from "../hooks/useChats";
import { useMessageList } from "../hooks/useMessageList";
import { sendMessageToParentWindow } from "../utils/sendMessageToParentWindow";
import { userDTO } from "../common/dtos/userDTO";
import { playSound } from "../utils/playSound";

const ChatApp = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [focus, setFocus] = useState<boolean>(false);

  const socket = useContext(SocketContext);

  const { chat, onOpenChat, changeUserStatus } = useChat();
  const { chats, setChatList, onStatusChange, setUnread } = useChats();
  const { setMessageList } = useMessageList();
  const { user } = useUser();
  const nodeRef = useRef(null);

  // Inicializar e definir intervalo para buscar mensagens nao lidas
  useEffect(() => {
    let interval: NodeJS.Timer;
    if (Object.keys(user).length > 0) {
      interval = setInterval(() => {
        getOfflineMessages(user.codu).then((unread) => {
          setUnread(unread);
        });
      }, 120000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [user]);

  useEffect(() => {
    // Escutar pelo resultado do login
    socket.on("loginResult", (response: { login: boolean }) => {
      // Caso sucesso
      if (response.login) {
        setLoading(false);

        const storage = {
          chatOpen: localStorage.getItem("chat_open") || "",
          chatWindow: localStorage.getItem("chat_window") || "",
        };

        // caso storage estiver vazio, define valores defaults
        if (storage.chatOpen === "" || storage.chatWindow === "") {
          localStorage.setItem("chat_window", "home");
          localStorage.setItem("chat_open", "false");
        }

        if (storage.chatOpen === "true") {
          sendMessageToParentWindow("open_chat");

          const talkInfo = localStorage.getItem("talk_info") || "";

          if (storage.chatWindow === "talk" && talkInfo !== "") {
            localStorage.setItem("chat_window", "talk");
            localStorage.setItem("chat_open", "true");

            const chatData = JSON.parse(talkInfo);

            onOpenChat(chatData);

            getMessages({
              talk: true,
              sender: chat.data.codu,
              receiver: user.codu,
              order: "desc",
              offset: 0,
              limit: 20,
            })
              .then((messages) => {
                setMessageList(messages);
              })
              .catch(() => {
                ErrorToast.fire({
                  text: "Erro ao buscar mensagens",
                });
              });
          }
        }

        getOfflineMessages(user.codu).then((unread) => {
          setUnread(unread);
        });
      }
    });

    return () => {
      socket.off("loginResult");
    };
  }, [user]);

  useEffect(() => {
    if (user.codu) {
      // Recebeu uma mensagem
      socket.on("atualiza_conversa", (data) => {
        if (data.notificar) {
          notificar(data, user.codu);
        }

        // Se a janela do chat estiver aberta na janela de conversa
        if (
          localStorage.getItem("chat_window") === "talk" &&
          localStorage.getItem("chat_open") === "true"
        ) {
          // Se o id do usuario/conversa é a que está aberta
          if (Number(data.user_codu) === Number(chat.data.codu)) {
            getMessages({
              sender: Number(chat.data.sender) || Number(data.user_codu),
              receiver: Number(chat.data.receiver) || Number(user.codu),
              talk: true,
              order: "desc",
              offset: 0,
              limit: 20,
            }).then((response) => {
              setMessageList(response);
            });

            if (!focus && data.mensagem) {
              playSound();
              getOfflineMessages(user.codu).then((unread) => {
                setUnread(unread);
              });
              notificar(data, user.codu);
            }
            if (focus && data.mensagem) {
              updateSeen(
                {
                  seen: 1,
                  sender: Number(chat.data.sender),
                  receiver: Number(chat.data.receiver),
                },
                socket
              );
              getOfflineMessages(user.codu).then((unread) => {
                setUnread(unread);
              });
            }
          } else if (data.mensagem && chat.data.codu) {
            CustomToast.fire({
              html: `<b>${data.nome}</b> diz: <br><div class="toast-text">${data.mensagem}<div>`,
            });
            playSound();
            getOfflineMessages(user.codu).then((unread) => {
              setUnread(unread);
            });
            notificar(data, user.codu);
          }
        }
        // Chat mostrando mas está na lista de conversas
        else if (
          localStorage.getItem("chat_window") === "home" &&
          localStorage.getItem("chat_open") === "true"
        ) {
          if (!data.mensagem) return;

          playSound();
          getOfflineMessages(user.codu).then((unread) => {
            setUnread(unread);
          });
          getChats(user.codu, data.user_codu)
            .then((res: chatDTO[]) => {
              setChatList(res);

              socket.emit("busca_status_usuarios", res);
            })
            .catch(() => {
              ErrorToast.fire({
                text: "Erro ao buscar conversas",
              });
            });
          getOfflineMessages(user.codu).then((unread) => {
            setUnread(unread);
          });
          notificar(data, user.codu);
        } else {
          // chat fechado
          playSound();
          getOfflineMessages(user.codu).then((unread) => {
            setUnread(unread);
          });

          sendMessageToParentWindow("open_chat");

          localStorage.setItem("chat_window", "talk");
          localStorage.setItem("chat_open", "true");

          const chatData = chats.find(
            (chatItem) => Number(chatItem.codu) === Number(data.user_codu)
          );

          if (chatData) {
            localStorage.setItem("talk_info", JSON.stringify(chatData));
            onOpenChat(chatData);
          }

          getMessages({
            talk: true,
            sender: Number(data.user_codu),
            receiver: user.codu,
            order: "desc",
            offset: 0,
            limit: 20,
          })
            .then((response) => {
              setLoading(false);
              setMessageList(response);
            })
            .catch(() => {
              ErrorToast.fire({
                text: "Erro ao buscar mensagens",
              });
            });
        }
      });
    }

    return () => {
      socket.off("atualiza_conversa");
    };
  }, [chat.open, user.codu, focus]);

  useEffect(() => {
    socket.on("atualiza_status_usuario", (changedUser: userDTO) => {
      if (chat.open && Number(chat.data.codu) === Number(changedUser.codu)) {
        changeUserStatus(changedUser.chat_status);
      } else {
        onStatusChange(changedUser);
      }
    });

    return () => {
      socket.off("atualiza_status_usuario");
    };
  }, [chat.open]);

  return (
    <div className="chat-wrapper">
      {!loading ? (
        <>
          <div className="header">
            <Header />
          </div>
          <TransitionGroup component={"div"} className="content">
            <CSSTransition
              nodeRef={nodeRef}
              classNames={"slider"}
              key={localStorage.getItem("chat_window") || "home"}
              timeout={300}
              mountOnEnter={false}
              unmountOnExit
            >
              <div
                ref={nodeRef}
                style={{ display: "flex", flexFlow: "column", height: "100%" }}
                className={chat.open ? "right" : "left"}
              >
                {!chat.open ? <ChatList /> : <Chat setFocus={setFocus} />}
              </div>
            </CSSTransition>
          </TransitionGroup>
        </>
      ) : (
        <Row>
          <Col
            style={{
              height: "100vh",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span>Conectando-se...</span>
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ChatApp;
