import { FC, memo } from "react";
import { messageDTO } from "../common/dtos/messageDTO";
import { useChat } from "../hooks/useChat";
import { useMessageList } from "../hooks/useMessageList";
import { useUser } from "../hooks/useUser";
import { getDateMark } from "./helpers/getDateMark";
import Message from "./message";

export const Messages: FC = () => {
  const { messages } = useMessageList();
  const { chat } = useChat();
  const { user } = useUser();

  let dateMark = "";

  return (
    <>
      {messages.map((message: messageDTO) => {
        const formattedDate = new Date(message.time_msg).toLocaleDateString(
          "pt-br",
          { year: "numeric", month: "2-digit", day: "2-digit" }
        );
        const date = getDateMark(dateMark, formattedDate);
        dateMark = formattedDate;

        const { _id: idMessage } = message;
        const isSender: boolean = +message.sender === chat.data.codu;

        return (
          <Message
            key={idMessage}
            message={message}
            date={date}
            imgUrl={isSender ? chat.data.img : user.img}
            userName={
              +message.sender === chat.data.codu ? chat.data.nome : user.nome
            }
            isSender={isSender}
          />
        );
      })}
    </>
  );
};

export default memo(Messages);
