import { AxiosResponse } from "axios";
import { Socket } from "socket.io-client";
import axiosClient from "../common/axios/axiosConfig";
import { userDTO } from "../common/dtos/userDTO";
import { ErrorToast } from "../common/toast/toast";

const updateStatus = (value: number, user: userDTO, socket: Socket): void => {
  axiosClient
    .put("update-status", {
      user: user.codu,
      status: value,
    })
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        socket.emit("atualizei_meu_status", user);
      }
    })
    .catch(() => {
      ErrorToast.fire({
        text: "Erro ao atualizar status",
      });
    });
};

export default updateStatus;
