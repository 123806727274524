import { Socket } from "socket.io-client";
import axiosClient from "../common/axios/axiosConfig";
import { ErrorToast } from "../common/toast/toast";

interface requestDTO {
  seen: number;
  sender: number;
  receiver: number;
}

// Response
/* interface responseDTO {
  description: string;
} */

const updateSeen = (data: requestDTO, socket: Socket): void => {
  axiosClient
    .put("/update-seen", data)
    .then(() => {
      socket.emit("update_messages", { codu: data.sender });
    })
    .catch(() => {
      ErrorToast.fire({
        text: "Erro ao visualizar mensagem",
      });
    });
};

export default updateSeen;
