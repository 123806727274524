import { FC, KeyboardEvent, useContext, useRef, useState } from "react";

import "../styles/header.scss";

import { FaChevronLeft, FaCircle } from "react-icons/fa";
import { GoTriangleDown, GoTriangleUp } from "react-icons/go";
import { Button, Overlay } from "react-bootstrap";
import { MdClose } from "react-icons/md";
import { createRipples } from "react-ripples";
import { setStatusClass } from "../utils/setStatusClass";
import updateStatus from "../api/updateStatus";
import { SocketContext } from "../context/SocketContext";
import { useChat } from "../hooks/useChat";
import { useUser } from "../hooks/useUser";
import { useChats } from "../hooks/useChats";
import { useMessageList } from "../hooks/useMessageList";
import { sendMessageToParentWindow } from "../utils/sendMessageToParentWindow";

const CloseRipples = createRipples({
  color: "var(--colors-primary)",
  during: 300,
});

export const Header: FC = () => {
  const [open, setOpen] = useState(false);
  const { chat, onBackToChatList } = useChat();
  const { setMessageList } = useMessageList();
  const { onSearch, unreadMessages } = useChats();
  const { user } = useUser();
  const target = useRef(null);

  const socket = useContext(SocketContext);
  const chatOpen: boolean = chat.open;

  // Troca o status do usuario
  const handleChangeStatus = (newStatus: number): void => {
    setOpen(false);
    if (newStatus.toString() !== user.chat_status) {
      user.chat_status = newStatus.toString();

      updateStatus(newStatus, user, socket);
    }
  };

  // Volta para a lista de conversas
  const handleBackToChatList = () => {
    localStorage.setItem("chat_window", "home");
    localStorage.setItem("talk_info", "");
    onBackToChatList();
    setMessageList([]);
  };

  // Busca conversa pelo nome
  const handleSearch = (e: KeyboardEvent<HTMLInputElement>) => {
    const nome: string = e.currentTarget.value.toLowerCase();

    onSearch(nome);
  };

  const handleClose = () => {
    sendMessageToParentWindow("close_chat");
    localStorage.setItem("chat_open", "false");
    localStorage.setItem("chat_window", "home");
    localStorage.setItem("talk_info", "");
  };

  const handleShowArrow = (): JSX.Element => {
    if (chatOpen) return <></>;

    if (open) {
      return <GoTriangleUp size={12} fill="#4c5667" />;
    }
    return <GoTriangleDown size={12} fill="#4c5667" />;
  };

  return (
    <div className="header-wrapper">
      {chatOpen && (
        <div className="back-button" onClick={() => handleBackToChatList()}>
          <FaChevronLeft size="1.333em" />
          {unreadMessages > 0 && (
            <span className="unread-badge" title="Mensagens não lidas">
              {unreadMessages}
            </span>
          )}
        </div>
      )}
      <div
        ref={target}
        onClick={!chatOpen ? () => setOpen((prev) => !prev) : undefined}
        className={`header-img ${chatOpen ? "chat-open" : ""}`}
      >
        <img
          src={chatOpen ? chat.data.img : user.img}
          className={setStatusClass(
            chatOpen ? Number(chat.data.chat_status) : +user.chat_status
          )}
        />
        {handleShowArrow()}
      </div>
      <Overlay
        target={target.current}
        show={open && !chatOpen}
        placement="bottom"
        onHide={() => setOpen(false)}
        rootClose={true}
      >
        {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
        {({ arrowProps, show: _show, ...props }) => (
          <div {...props} className="status-dropdown-wrapper">
            <ul className="dropdown-list">
              <li
                className="dropdown-item"
                onClick={() => handleChangeStatus(1)}
              >
                <FaCircle fill="#5bf73f" />
                Online
              </li>
              <li
                className="dropdown-item"
                onClick={() => handleChangeStatus(3)}
              >
                <FaCircle fill="#ff1d1d" />
                Ocupado
              </li>
              <li
                className="dropdown-item"
                onClick={() => handleChangeStatus(4)}
              >
                <FaCircle fill="#ff901d" />
                Ausente
              </li>
              <li
                className="dropdown-item"
                onClick={() => handleChangeStatus(2)}
              >
                <FaCircle fill="#dee2e6" />
                Invisível
              </li>
            </ul>
          </div>
        )}
      </Overlay>

      <div className="header-user-info">
        {chatOpen ? (
          <span className="nome-usuario">{chat.data.nome}</span>
        ) : (
          <>
            <span className="nome-usuario">{user.nome}</span>
            <input
              className="search-contact"
              type="text"
              placeholder="Buscar contato..."
              maxLength={100}
              onKeyUp={(e) => handleSearch(e)}
            />
          </>
        )}
      </div>

      <div className="close">
        <CloseRipples>
          <Button onClick={() => handleClose()}>
            <MdClose size={25} fill="#FFFFFF" />
          </Button>
        </CloseRipples>
      </div>
    </div>
  );
};
