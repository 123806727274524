import { sendMessageToParentWindow } from "./sendMessageToParentWindow";

interface expectedParams {
  user_codu: number;
  nome: string;
  mensagem: string;
}

export const notificar = (params: expectedParams, user: number) => {
  const data = {
    ...params,
    user,
  };
  sendMessageToParentWindow("notify", data);
};
