import { AxiosResponse } from "axios";
import axiosClient from "../common/axios/axiosConfig";
import { chatDTO } from "../common/dtos/chatDTO";

const getChats = async (
  userCodu: number,
  senderCodu?: number
): Promise<chatDTO[]> => {
  const response: AxiosResponse<chatDTO[]> = await axiosClient.get(
    "list-talks",
    {
      params: {
        user: userCodu,
      },
    }
  );

  if (senderCodu) {
    return response.data.map((chatItem) => {
      if (Number(chatItem.codu) === Number(senderCodu)) {
        return {
          ...chatItem,
          notif: true,
        };
      }
      return { ...chatItem, notif: false };
    });
  }

  return response.data;
};

export default getChats;
