import { AxiosResponse } from "axios";
import axiosClient from "../common/axios/axiosConfig";
import { userDTO } from "../common/dtos/userDTO";
import { ErrorToast } from "../common/toast/toast";

interface IResponse {
  clinica: { id_reseller: string };
  usuario: userDTO;
}

const validateUser = (): Promise<IResponse | void> => {
  return axiosClient
    .get("validate-user")
    .then((info: AxiosResponse<IResponse>) => {
      return info.data;
    })
    .catch(() => {
      ErrorToast.fire({
        text: "Erro ao validar usuario! Será realizado uma nova tentativa...",
      });
    });
};

export default validateUser;
