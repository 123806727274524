export const formatMessageDate = (msgDate: string) => {
  const date: Date = new Date(msgDate);
  const today: Date = new Date();
  const yesterday: Date = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate() - 1,
    0
  );

  const inicio: Date = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    0
  );
  const fim: Date = new Date(
    today.getFullYear(),
    today.getMonth(),
    today.getDate(),
    24
  );

  if (date.getTime() > inicio.getTime() && date.getTime() < fim.getTime()) {
    return `Hoje ${date.toLocaleTimeString("pt-br", {
      hour: "2-digit",
      minute: "2-digit",
    })}`;
  }
  if (date.getTime() > yesterday.getTime()) {
    return `Ontem ${date.toLocaleTimeString("pt-br", {
      hour: "2-digit",
      minute: "2-digit",
    })}`;
  }

  return date.toLocaleDateString("pt-br", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};
