import { FC, useCallback, useEffect, useState } from "react";
import { createContext } from "use-context-selector";
import validateUser from "../api/validateUser";
import { chatDTO } from "../common/dtos/chatDTO";
import { messageDTO } from "../common/dtos/messageDTO";
import { userDTO } from "../common/dtos/userDTO";
import { ErrorToast } from "../common/toast/toast";
import { sortByDate } from "../utils/sortChatList";

type Chat = {
  open: boolean;
  data: chatDTO;
};

export type ChatContextType = {
  user: userDTO;
  chat: Chat;
  messages: messageDTO[];
  chats: chatDTO[];
  unreadMessages: number;
  setUserInfo: (userInfo: userDTO) => void;
  changeUserStatus: (status: string) => void;
  putNewMessages: (newMessages: messageDTO[]) => void;
  addNewMessage: (newMessage: messageDTO) => void;
  setMessageList: (messageList: messageDTO[]) => void;
  onBackToChatList: () => void;
  onOpenChat: (openedChat: chatDTO) => void;
  onSearch: (nome: string) => void;
  setChatList: (chatList: chatDTO[]) => void;
  setUnread: (totalUnread: number) => void;
  onStatusChange: (changedUser: userDTO) => void;
};

export const ChatContext = createContext({} as ChatContextType);

export const ChatProvider: FC = ({ children }) => {
  const [user, setUser] = useState<userDTO>({} as userDTO);
  const [chat, setChat] = useState<Chat>({
    open:
      localStorage.getItem("chat_window") === "talk" &&
      localStorage.getItem("talk_info") !== "",
    data:
      localStorage.getItem("talk_info") !== ""
        ? JSON.parse(localStorage.getItem("talk_info") || "{}")
        : ({} as chatDTO),
  });
  const [messages, setMessages] = useState<messageDTO[]>([] as messageDTO[]);
  const [chats, setChats] = useState<chatDTO[]>([] as chatDTO[]);
  const [unreadMessages, setUnreadMessages] = useState<number>(0);

  const setUserInfo = (userInfo: userDTO): void => {
    setUser((state) => ({ ...state, ...userInfo }));
  };

  const changeUserStatus = useCallback((status: string): void => {
    setChat(({ data }) => ({
      open: true,
      data: {
        ...data,
        chat_status: status,
      },
    }));
  }, []);

  const putNewMessages = useCallback((newMessages: messageDTO[]): void => {
    setMessages((prev) => [...prev, ...newMessages]);
  }, []);

  const addNewMessage = useCallback((newMessage: messageDTO): void => {
    setMessages((prev) => [newMessage, ...prev]);
  }, []);

  const setMessageList = useCallback((messageList: messageDTO[]): void => {
    setMessages(messageList);
  }, []);

  const onBackToChatList = useCallback(() => {
    setChat({ open: false, data: {} as chatDTO });
  }, []);

  const onOpenChat = useCallback((openedChat: chatDTO) => {
    setChat({ open: true, data: openedChat });
  }, []);

  const onSearch = useCallback((nome: string): void => {
    setChats((prev) =>
      prev.map((item) => ({
        ...item,
        hidden: nome === "" ? false : !item.nome.toLowerCase().includes(nome),
      }))
    );
  }, []);

  const setChatList = useCallback((chatList: chatDTO[]): void => {
    setChats(chatList.sort((a: chatDTO, b: chatDTO) => sortByDate(a, b)));
  }, []);

  const setUnread = useCallback((totalUnread: number): void => {
    setUnreadMessages(totalUnread);
  }, []);

  const onStatusChange = useCallback((changedUser: userDTO) => {
    setChats((prev) =>
      prev.map((oldChat) => ({
        ...oldChat,
        chat_status:
          Number(oldChat.codu) === Number(changedUser.codu)
            ? changedUser.chat_status
            : oldChat.chat_status,
      }))
    );
  }, []);

  // Valida usuario
  const getUserInfo = () => {
    validateUser().then((info) => {
      if (info) {
        const { usuario, clinica } = info;
        const newUser: userDTO = {
          chat_status: usuario.chat_status,
          codu: usuario.codu,
          id_reseller: clinica.id_reseller,
          img: usuario.img,
          nome: usuario.nome,
          permissao: usuario.permissao,
          usuclin: usuario.usuclin,
          volume_chat: usuario.volume_chat,
        };

        setUserInfo(newUser);
      }
    });
  };

  // Valida usuario ao renderizar o contexto
  useEffect(() => {
    getUserInfo();
  }, []);

  // Caso não tenha conseguido tenta novamente a cada 5 segundos
  useEffect(() => {
    let count = 0;

    const validateUserInterval = setInterval(() => {
      if (count < 4) {
        if (Object.keys(user).length === 0) {
          count += 1;
          getUserInfo();
        } else {
          clearInterval(validateUserInterval);
        }
      } else {
        ErrorToast.fire({
          text: "Erro ao validar usuário, recarregue a página e tente novamente.",
          timer: 20000,
          showConfirmButton: true,
          confirmButtonText: "OK",
          confirmButtonColor: "rgb(155, 7, 7)",
        });
        clearInterval(validateUserInterval);
      }
    }, 5000);

    return () => {
      clearInterval(validateUserInterval);
    };
  }, [user]);

  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <ChatContext.Provider
      value={{
        user,
        chat,
        messages,
        chats,
        unreadMessages,
        setUserInfo,
        changeUserStatus,
        putNewMessages,
        addNewMessage,
        setMessageList,
        onBackToChatList,
        onOpenChat,
        onSearch,
        setChatList,
        setUnread,
        onStatusChange,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};
