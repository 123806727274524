import axiosClient from "../common/axios/axiosConfig";
import { messageDTO } from "../common/dtos/messageDTO";

interface requestDTO {
  talk: boolean;
  sender: number;
  receiver: number;
  order: "desc" | "asc";
  offset: number;
  limit: number;
}

const getMessages = async (params: requestDTO): Promise<messageDTO[]> => {
  const response = await axiosClient.get("list-messages", {
    params,
  });

  return response.data;
};

export default getMessages;
