export const getDateMark = (
  dateMark: string,
  formattedDate: string
): string => {
  const today = new Date();
  const options: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };
  const formattedToday = today.toLocaleDateString("pt-br", options);

  if (dateMark !== formattedDate && dateMark !== "") {
    if (dateMark === formattedToday) {
      return "Hoje";
    }
    if (
      dateMark ===
      new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - 1
      ).toLocaleDateString("pt-br", options)
    ) {
      return "Ontem";
    }

    return formattedDate;
  }

  return "";
};
