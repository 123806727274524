import { useContextSelector } from "use-context-selector";
import { ChatContext } from "../context/ChatContext";

export const useUser = () => {
  const user = useContextSelector(ChatContext, (context) => context.user);

  const setUserInfo = useContextSelector(
    ChatContext,
    (context) => context.setUserInfo
  );

  return { user, setUserInfo };
};
