import { FC, memo } from "react";
import { messageDTO } from "../common/dtos/messageDTO";

interface IProps {
  date: string;
  message: messageDTO;
  imgUrl: string;
  userName: string;
  isSender: boolean;
}

const Message: FC<IProps> = (props) => {
  return (
    <>
      {props.date !== "" && (
        <div className="message mark">
          <mark>{props.date}</mark>
        </div>
      )}
      <div className={`message ${props.isSender ? "sender" : "receiver"}`}>
        <div className="conversation-text">
          <p>{props.message.message}</p>
          <span className="conversation-time">
            {new Date(props.message.time_msg).toLocaleTimeString("pt-br", {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </span>
        </div>
      </div>
      {props.message.last && (
        <div className="message mark">
          <mark>Nenhuma mensagem restante.</mark>
        </div>
      )}
    </>
  );
};

export default memo(Message);
