import { AxiosResponse } from "axios";
import axiosClient from "../common/axios/axiosConfig";
import { ErrorToast } from "../common/toast/toast";
import { sendMessageToParentWindow } from "../utils/sendMessageToParentWindow";

interface responseDTO {
  count: number;
}

const getOfflineMessages = (codu: number): Promise<number> => {
  return axiosClient
    .get("offline-messages", {
      params: { receiver: codu },
    })
    .then((response: AxiosResponse<responseDTO>) => {
      if (response.status === 200) {
        const messageCount = response.data.count;

        sendMessageToParentWindow("change_title", {
          messageCount,
        });

        return messageCount;
      }
      return 0;
    })
    .catch(() => {
      ErrorToast.fire({
        text: "Erro ao buscar mensagens não lidas",
      });
      return 0;
    });
};

export default getOfflineMessages;
