import {
  Dispatch,
  FC,
  KeyboardEvent,
  SetStateAction,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

import "../styles/chat.scss";
import "../styles/messages.scss";

import { useForm } from "react-hook-form";
import { FaAngleDown } from "react-icons/fa";
import { GrSend } from "react-icons/gr";
import { Button } from "react-bootstrap";
import TextAreaAutosize from "react-textarea-autosize";
import updateSeen from "../api/updateSeen";
import getMessages from "../api/getMessages";
import insertMessage from "../api/insertMessage";
import { SocketContext } from "../context/SocketContext";
import Messages from "./messages";
import { ErrorToast } from "../common/toast/toast";
import { useChat } from "../hooks/useChat";
import { useMessageList } from "../hooks/useMessageList";
import { useUser } from "../hooks/useUser";
import getOfflineMessages from "../api/getOfflineMessages";
import { useChats } from "../hooks/useChats";

interface expectedProps {
  setFocus: Dispatch<SetStateAction<boolean>>;
}

type Form = {
  message: string;
};

export const Chat: FC<expectedProps> = (props) => {
  const socket = useContext(SocketContext);

  const { chat } = useChat();
  const { setUnread } = useChats();
  const { user } = useUser();
  const { messages, putNewMessages, addNewMessage, setMessageList } =
    useMessageList();

  const { register, handleSubmit, reset } = useForm<Form>();
  const [loading, setLoading] = useState<boolean>(true);

  const [intersecting, setIntersecting] = useState<boolean>(false);
  const refConversa = useRef<HTMLDivElement | null>(null);
  const [loadMore, setLoadMore] = useState<boolean>(true);
  const [loadingMore, setLoadingMore] = useState<boolean>(false);
  const [offset, setOffset] = useState<number>(0);

  const [backStartButton, setBackStartButton] = useState<boolean>(false);

  const end = useRef<HTMLDivElement>(null);

  // Buscar mensagens
  useEffect(() => {
    getMessages({
      talk: true,
      sender: chat.data.codu,
      receiver: user.codu,
      order: "desc",
      offset: 0,
      limit: 20,
    })
      .then((response) => {
        setLoading(false);
        setMessageList(response);
      })
      .catch(() => {
        setLoading(false);
        ErrorToast.fire({
          text: "Erro ao buscar mensagens",
        });
      });
  }, [user, chat.open]);

  // Coloca as mensagens como lidas
  useEffect(() => {
    if (chat.data.codu) {
      const data = {
        seen: 1,
        sender: Number(chat.data.codu),
        receiver: Number(user.codu),
      };

      updateSeen(data, socket);
    }
  }, [user, chat.data, socket]);

  // Enviar nova mensagem e adicionar na listagem
  const onSubmit = (data: Form) => {
    const messageTxt = data.message.trim();
    if (messageTxt.length === 0) {
      return;
    }
    reset();

    const newMessage = {
      message: messageTxt,
      sender: user.codu.toString(),
      receiver: chat.data.codu.toString(),
      seen: 0,
      time_msg: new Date().toISOString(),
      _id: new Date().getTime().toString(),
    };

    addNewMessage(newMessage);

    insertMessage(
      {
        sender: Number(newMessage.sender),
        receiver: Number(newMessage.receiver),
        message: messageTxt.replace(/'/g, ""),
      },
      socket
    );

    getOfflineMessages(user.codu).then((unread) => {
      setUnread(unread);
    });

    if (end.current) {
      end.current.scrollIntoView({ behavior: "smooth" });
    }
    setLoadMore(true);
    setOffset(0);
  };

  const handleKeyUp = (ev: KeyboardEvent<HTMLTextAreaElement>) => {
    if (ev.key === "Enter" && !ev.ctrlKey && !ev.shiftKey) {
      handleSubmit(onSubmit)();
    }
  };

  const handleTextareaFocus = () => {
    props.setFocus(true);

    const data = {
      seen: 1,
      sender: Number(chat.data.codu),
      receiver: Number(user.codu),
    };

    updateSeen(data, socket);

    setTimeout(() => {
      getOfflineMessages(user.codu).then((unread) => {
        setUnread(unread);
      });
    }, 1000);
  };

  const handleScrollLoadMore = async () => {
    if (refConversa.current) {
      // Quando o elemento observado estiver na tela e ainda tiver mensagens para carregar, busca mais mensagens
      if (intersecting && loadMore) {
        setLoadMore(false);
        setLoadingMore(true);
        setOffset((prev) => prev + 1);
        await getMessages({
          talk: true,
          sender: chat.data.codu,
          receiver: user.codu,
          order: "desc",
          offset: (offset + 1) * 20,
          limit: 20,
        })
          .then((response) => {
            if (response.length > 0) {
              if (response.length < 20) {
                response[response.length - 1].last = true;
              }
              setLoadMore(true);
              setIntersecting(false);
              putNewMessages(response);
            } else {
              setLoadMore(false);
            }
            setLoadingMore(false);
          })
          .catch(() => {
            ErrorToast.fire({
              text: "Erro ao buscar mensagens",
            });
          });
      }

      // Exibe botão para voltar ao começo das mensagens
      if (refConversa.current.scrollTop < -530) {
        setBackStartButton(true);
      } else {
        setBackStartButton(false);
      }
    }
  };

  // Caso o elemento entre ou saia da tela, a funcao é chamada
  const handleIsIntersecting = (entries: IntersectionObserverEntry[]) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      setIntersecting(true);
    }
  };

  // Pega o elemento da ultima mensagem e observa quando ele ficará visível
  useEffect(() => {
    const childElement = refConversa.current?.children[messages.length - 1];

    const observer = new IntersectionObserver(handleIsIntersecting, {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    });
    if (childElement) {
      observer.observe(childElement);
    }

    return () => {
      if (childElement) observer.unobserve(childElement);
    };
  }, [refConversa.current, messages]);

  return (
    <div className="component">
      {!loading ? (
        <div className="chat-wrapper">
          {messages.length > 0 ? (
            <div
              ref={refConversa}
              onScroll={() => {
                handleScrollLoadMore();
              }}
              className="messages-wrapper"
            >
              <div ref={end}></div>
              {backStartButton && (
                <div className="back-start-btn">
                  <Button
                    onClick={() =>
                      refConversa.current
                        ? refConversa.current.scrollTo({
                            top: 0,
                            behavior: "smooth",
                          })
                        : null
                    }
                  >
                    <FaAngleDown size={20} fill="#FFFFFF" />
                  </Button>
                </div>
              )}
              <Messages />
              {loadingMore && (
                <div className="loading-more">Carregando mensagens...</div>
              )}
            </div>
          ) : (
            <div className="messages-wrapper">
              <li className="message mark" key="empty-message">
                <mark>Conversa vazia</mark>
              </li>
            </div>
          )}
          <div className="send-message-wrapper">
            <form onSubmit={handleSubmit(onSubmit)}>
              <TextAreaAutosize
                className="message-content"
                onFocus={() => handleTextareaFocus()}
                placeholder="Digite aqui..."
                {...register("message")}
                minRows={1}
                maxRows={6}
                onKeyUp={handleKeyUp}
                onBlur={() => props.setFocus(false)}
                autoFocus
              />
              <Button className="send-message-btn" type="submit">
                <GrSend size={22} />
              </Button>
            </form>
          </div>
        </div>
      ) : (
        <div
          className="loading"
          style={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span>Carregando mensagens...</span>
        </div>
      )}
    </div>
  );
};
