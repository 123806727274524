import { AxiosResponse } from "axios";
import { Socket } from "socket.io-client";
import axiosClient from "../common/axios/axiosConfig";
import { ErrorToast } from "../common/toast/toast";

interface requestDTO {
  sender: number;
  receiver: number;
  message: string;
}

const insertMessage = (data: requestDTO, socket: Socket): void => {
  axiosClient
    .post("insert-message", data)
    .then((response: AxiosResponse) => {
      if (response.status === 200) {
        socket.emit("envia_nova_mensagem", data);
      }
    })
    .catch(() => {
      ErrorToast.fire({
        text: "Erro ao enviar mensagem",
      });
    });
};

export default insertMessage;
