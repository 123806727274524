import { useContextSelector } from "use-context-selector";
import { ChatContext } from "../context/ChatContext";

export const useMessageList = () => {
  const messages = useContextSelector(
    ChatContext,
    (context) => context.messages
  );

  const putNewMessages = useContextSelector(
    ChatContext,
    (context) => context.putNewMessages
  );

  const addNewMessage = useContextSelector(
    ChatContext,
    (context) => context.addNewMessage
  );

  const setMessageList = useContextSelector(
    ChatContext,
    (context) => context.setMessageList
  );

  return { messages, putNewMessages, addNewMessage, setMessageList };
};
