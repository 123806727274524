import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export const CustomToast = withReactContent(
  Swal.mixin({
    toast: true,
    position: "top",
    customClass: {
      popup: "toast",
    },
    showConfirmButton: false,
    timer: 5000,
    timerProgressBar: false,
  })
);

export const ErrorToast = CustomToast.mixin({
  timer: 2500,
  icon: "error",
  iconColor: "white",
  customClass: {
    popup: "error",
  },
});
