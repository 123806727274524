import { CHAT_STATUS_ENUM } from "../common/enums/chatStatusEnum";

export const setStatusClass = (status: number | undefined): string => {
  switch (status) {
    case CHAT_STATUS_ENUM.ONLINE:
      return "status-disponivel";
    case CHAT_STATUS_ENUM.OCUPADO:
      return "status-ocupado";
    case CHAT_STATUS_ENUM.AUSENTE:
      return "status-ausente";
    default:
      // Usuario Offline
      return "";
  }
};
