import { FaRegThumbsUp, FaWhatsapp } from "react-icons/fa";
import { useUser } from "../hooks/useUser";
import { sendMessageToParentWindow } from "../utils/sendMessageToParentWindow";

const logoUnimed = require("../assets/images/unimed_logo.png");
const logoAmplimed = require("../assets/images/logo_icon.png");

const SupportCards = () => {
  const { user } = useUser();

  // Abre o chat de suporte da Unimed com base no reseller
  const handleOpenChatUnimed = (reseller: number) => {
    switch (reseller) {
      case 2:
        window.open(
          "https://tawk.to/chat/613f55b6d326717cb68131bc/1fffmdl78",
          "popup",
          "width=500,height=600,scrolling=auto,top=0,left=0"
        );
        break;
      case 4:
        window.open(
          "https://jivo.chat/snBXX4d3YP",
          "popup",
          "width=500,height=600,scrolling=auto,top=0,left=0"
        );
        break;
      case 20:
        window.open(
          "https://unimedriopreto.chat.blip.ai/?appKey=cm91dGVycHJlc3RhZG9yYW1wbGltZWQxOjNlMzRlNGUyLTVkZmUtNDE2ZS04M2I4LTZmZjYxMTg0N2Q0NQ==",
          "popup",
          "width=470,height=600,scrolling=auto,top=0,left=0"
        );
        break;
      case 26:
        window.open(
          "https://servidorseguro.mysuite1.com.br/client/login.php?sl=usc",
          "popup",
          "width=470,height=600,scrolling=auto,top=0,left=0"
        );
        break;
      case 48:
        window.open(
          "https://jivo.chat/cJsDPE1b3P",
          "popup",
          "width=500,height=600,scrolling=auto,top=0,left=0"
        );
        break;
      default:
        window.open(
          "https://unimedsjrp.plusoftomni.com.br/forms/chat-unimed-sjrp-secretarias.forms.main?queue=5",
          "popup",
          "width=470,height=600,scrolling=auto,top=0,left=0"
        );
        break;
    }
  };

  // Abre o chat de suporte da GVIX
  const handleOpenChatGVIX = () => {
    const message = encodeURI(
      `Olá suporte GVIX, me chamo ${user.nome} da unidade teste, preciso de auxílio.`
    );

    message.replace("+", "%20");
    window.open(
      `https://web.whatsapp.com/send?phone=5527996069551&text=${message}`
    );
  };

  // Define o status do chat de suporte da Amplimed
  const setStatusSuporte = (): string => {
    const today = new Date();
    if (
      today.getDay() !== 6 &&
      today.getDay() !== 0 &&
      today.getHours() >= 9 &&
      today.getHours() < 18
    ) {
      return "status-disponivel";
    }
    return "status-ausente";
  };

  return (
    <div className="support-cards">
      {/* Suporte UNIMED */}
      {[2, 4, 11, 20, 26, 48].includes(Number(user.id_reseller)) && (
        <div
          className="chat support-card"
          title="Clique para entrar em contato com o suporte da UNIMED."
          onClick={() => handleOpenChatUnimed(Number(user.id_reseller))}
        >
          <div className="chat-img">
            <img src={logoUnimed} />
          </div>
          <div className="chat-info">
            <span className="user-name">Suporte UNIMED</span>
            <div className="message-row">Fale com a operadora!</div>
          </div>
        </div>
      )}
      {/* Suporte GVIX */}
      {[4192, 4829, 10].includes(Number(user.usuclin)) && (
        <div
          className="chat support-card"
          title="Clique para entrar em contato com o suporte da GVIX."
          onClick={() => handleOpenChatGVIX()}
        >
          <div className="chat-img">
            <FaWhatsapp size={44} fill="#00b19d" />
          </div>
          <div className="chat-info">
            <span className="user-name">Suporte GVIX</span>
            <div className="message-row">Fale com a operadora!</div>
          </div>
        </div>
      )}
      {/* Suporte Amplimed */}
      <div
        className="chat support-card"
        title="Clique para entrar em contato com o suporte."
        onClick={() => sendMessageToParentWindow("open_intercom")}
      >
        <div className="chat-img">
          <img
            className={`support ${setStatusSuporte()}`}
            src={logoAmplimed}
            style={{
              objectFit: "contain",
            }}
          />
        </div>
        <div className="chat-info">
          <span className="user-name">Suporte Amplimed</span>
          <div
            className="message-row support-card"
            style={{ color: "var(--colors-primary)" }}
          >
            Surgiram dúvidas? Nos chame!{" "}
            <FaRegThumbsUp
              size={14}
              fill={"var(--colors-primary)"}
              style={{ marginLeft: "5px" }}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SupportCards;
