import { useContextSelector } from "use-context-selector";
import { ChatContext } from "../context/ChatContext";

export const useChat = () => {
  const chat = useContextSelector(ChatContext, (context) => context.chat);
  const changeUserStatus = useContextSelector(
    ChatContext,
    (context) => context.changeUserStatus
  );
  const onBackToChatList = useContextSelector(
    ChatContext,
    (context) => context.onBackToChatList
  );
  const onOpenChat = useContextSelector(
    ChatContext,
    (context) => context.onOpenChat
  );

  return {
    chat,
    changeUserStatus,
    onBackToChatList,
    onOpenChat,
  };
};
