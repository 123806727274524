import { useContextSelector } from "use-context-selector";
import { ChatContext } from "../context/ChatContext";

export const useChats = () => {
  const chats = useContextSelector(ChatContext, (context) => context.chats);
  const unreadMessages = useContextSelector(
    ChatContext,
    (context) => context.unreadMessages
  );

  const onSearch = useContextSelector(
    ChatContext,
    (context) => context.onSearch
  );

  const setChatList = useContextSelector(
    ChatContext,
    (context) => context.setChatList
  );

  const setUnread = useContextSelector(
    ChatContext,
    (context) => context.setUnread
  );

  const onStatusChange = useContextSelector(
    ChatContext,
    (context) => context.onStatusChange
  );

  return {
    chats,
    unreadMessages,
    onSearch,
    setChatList,
    setUnread,
    onStatusChange,
  };
};
